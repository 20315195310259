<!-- eslint-disable prettier/prettier -->
<!-- 
   页面 区域管理主流程 (这里的Table 用了 elemnt-ui)
-->
<template>
  <div id="collect-village-page" class="data-list-container dark-layout">
    <vx-card>
      <div class="table_height_container">
        <!-- 操作按钮 -->
        <div class="operate-container mb-3" v-if="account === 'admin'">
          <vs-button size="small" type="relief" @click="createVillage()">新增社区</vs-button>
        </div>
        <!-- 整体表 -->
        <el-table class="table_theme" :data="villages" ref="villagesTable" v-loading="loading">
          <el-table-column type="index" width="60" label="序号">
            <template slot-scope="scope">
                <span>{{(pageOpt.currentPage - 1) * pageOpt.currentPageSize + scope.$index + 1}}</span>
            </template>
          </el-table-column>
          <el-table-column property="space_name" label="社区名称" width="200"  min-width="200" show-overflow-tooltip></el-table-column>
          <el-table-column property="extend.address" label="地址" min-width="200" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="100" header-align="center" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" class="primary" @click="manageBuildings(scope.row)">楼栋管理</el-button>
              <!-- <el-button type="text" size="small" class="primary" @click="markSpot(scope.row)">标记点位</el-button> -->
              <!-- <el-button type="text" size="small" class="primary" @click="editData(scope.row)">编辑社区</el-button> -->
              <!-- <el-button type="text" size="small" class="primary" @click="delData(scope.row)">删除</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="bottom mt-5 text-center">
          <el-pagination  class="pagination" layout="total, sizes, prev, pager, next"  prev-text="上一页" next-text="下一页"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageOpt.currentPage"
            :page-sizes="pageOpt.pageSizes"
            :page-size="pageOpt.currentPageSize"
            :pager-count="pageOpt.pageCount"
            :total="pageOpt.totalPageSize"
            :hide-on-single-page="pageOpt.hideSinglePage"
          ></el-pagination>
        </div>
      </div>
    </vx-card>

    <!-- 弹出框 -->
    <villageDialogCURD :dialogFormOpt="dialogFormOpt" :dialogFormData="dialogFormData" :dialogFormVisible="dialogFormVisible"  @handleDiaLogClose="handleDiaLogClose(arguments)"></villageDialogCURD>
  </div>
</template>

<script>
// 导入弹框
import villageDialogCURD from "./VillageDialogCURD";

export default {
  data() {
    return {
      // 选中数据
      selected: [],
      // 渲染数据
      villages: [],
      // 加载状态
      loading: false,
      // 弹出框状态
      dialogFormVisible: false,
      // 弹出框所需数据
      dialogFormData: {},
      // 弹出框所做的操作: CRUD
      dialogFormOpt: "",
      // 系统权限
      roleMenus: [],
      // 省列表:
      provinces: [],
      // 当前登陆账号
      account: this.$store.state.auth.account,
      // 当前页
      pageOpt: {
        currentPage: 1,
        currentPageSize: 10,
        totalPageSize: 0,
        hideSinglePage: true,
        pageSizes: [10, 20, 30],
        pageCount: 5
      }
    };
  },
  methods: {
    // 新增社区
    createVillage() {
      // 如果 省数据 没加载到 不让 进去
      if (this.provinces.length > 0) {
        this.dialogFormOpt = this.$CONST.OPTRATE.C;
        this.dialogFormVisible = true;
        this.dialogFormData = Object.assign(
          {
            provinces: this.provinces,
            dialogFormOpt: this.$CONST.OPTRATE.C
          },
          {
            extend: {
              address: "",
              altitude: 0,
              areaAddress: "",
              gis_type: 1,
              latitude: 0,
              longitude: 0
            }
          }
        );
      } else {
        this.$message({
          message: "数据正在加载,请稍后打开",
          type: "warning",
          duration: 1500,
          offset: 100
        });
      }
    },
    // 楼栋管理
    manageBuildings(row) {
      this.$router.push({
        name: "communityBuildingList",
        params: { id: row.space_code }
      });
    },
    // 标注点位
    markSpot(row) {
      console.log(row);
    },
    // 编辑社区
    editData(row) {
      this.dialogFormOpt = this.$CONST.OPTRATE.U;
      this.dialogFormVisible = true;
      this.dialogFormData = Object.assign(
        {
          provinces: this.provinces,
          dialogFormOpt: this.$CONST.OPTRATE.U
        },
        row
      );
    },
    // 删除社区
    delData(row) {
      this.dialogFormOpt = this.$CONST.OPTRATE.D;
      this.dialogFormVisible = true;
      this.dialogFormData = Object.assign(
        {
          provinces: this.provinces,
          dialogFormOpt: this.$CONST.OPTRATE.D
        },
        row
      );
    },
    // 列表序号
    rowClassName({ row, rowIndex }) {
      row.xh =
        rowIndex +
        1 +
        (this.pageOpt.currentPage - 1) * this.pageOpt.currentPageSize;
    },
    // 列表选中
    handleSelectionChange(val) {
      this.selected = val;
      // console.log("角色列表选中:", this.selected);
    },
    // 调整单页条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageOpt.currentPageSize = val;
      this.getRoleList();
    },
    // 调整当前页数
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageOpt.currentPage = val;
      this.getRoleList();
    },
    // 加载动画
    loadAnimation() {
      this.loading = true;
      return new Promise(resolve => {
        setTimeout(() => {
          this.loading = false;
          resolve(true);
        }, 1000);
      });
    },
    // 请求小区(空间)列表  获取用户区域权限
    async getVillageList() {
      this.loading = true;
      try {
        let params = {
          pageNum: this.pageOpt.currentPage,
          pageSize: this.pageOpt.currentPageSize
        };
        let result = await this.$apis.ODC("获取用户区域权限", params);
        if (this.$apis.isOk(result)) {
          this.villages = result.data;
        } else {
          this.showNotify("warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.showNotify("danger", err.message ? err.message : err);
      }
      this.loading = false;
    },
    // 获取空间列表 (省市区)
    async getCascaderData() {
      try {
        let result = await this.$apis.ODC("获取空间列表", { partentCode: "" });
        if (this.$apis.isOk(result)) {
          this.provinces = result.data;
        } else {
          this.showNotify("warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.showNotify("danger", err.message ? err.message : err);
      }
    },
    // 提示框
    showNotify(state, text) {
      let title,
        color = "";
      switch (state) {
        case "success":
          title = "成功";
          color = "success";
          break;
        case "danger":
          title = "错误";
          color = "danger";
          break;
        case "warning":
          title = "警告";
          color = "warning";
          break;
      }
      this.$vs.notify({
        time: 2500,
        title: title,
        text: text,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    // 当子模态框关闭回调
    handleDiaLogClose(isRefresh) {
      this.dialogFormVisible = false;
      if (isRefresh[0]) this.getVillageList();
    }
  },
  created() {
    // 请求数据
    this.getVillageList();
    // 只有admin 才能创建小区
    if (this.account === "admin") {
      this.getCascaderData();
    }
  },
  components: {
    villageDialogCURD
  }
};
</script>

<style lang="scss" scope="scope">
#collect-village-page {
  .table_height_container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 200px);
  }
  .el-button.is-disabled.el-button--text {
    text-decoration: line-through;
  }
}
</style>
