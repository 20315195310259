<template>
  <el-dialog
    customClass="VillageDialogCURD"
    :visible.sync="isVisible"
    :destroy-on-close="true"
    :show-close="true"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <!-- 弹窗头 -->
    <span slot="title" class="el-dialog__title">
      <vs-avatar class="mr-3" color="primary" text="C" size="22px" />
      <h5 class="line-h-30">{{ dialogTitle }}</h5>
      <vs-divider></vs-divider>
    </span>
    <!-- 弹窗内容 -->
    <el-form class="common-form" :model="form" :rules="rules" ref="ruleForm" label-width="100px" label-position="top">
      <!-- 表单认证(社区名称) -->
      <el-form-item label="社区名称" prop="spaceName">
        <!-- <el-input v-model="form.spaceName"  prefix-icon="el-icon-user" placeholder="请输入社区名称"  autocomplete="off" :disabled="isDisable" @input="e => form.spaceName = validForbid(e)"></el-input> -->
        <el-input v-model="form.spaceName"  prefix-icon="el-icon-user" placeholder="请输入社区名称"  autocomplete="off" :disabled="isDisable"></el-input>
      </el-form-item>

      <!-- 表单认证(行政单位) -->
      <div
        class="vs-component vs-con-input-label vs-input w-full mt-6 mb-6 vs-input-primary"
      >
        <label for="" class="vs-input--label">
          行政单位<span style="font-size: 0.6rem;">(必填)</span></label
        >
        <el-cascader
          clearable
          v-if="reFresh"
          ref="cascader"
          :placeholder="cascaderPlaceholder"
          :disabled="!isShowCascader"
          class="w-full"
          :props="cascader"
          @change="handleCascaderChange"
        ></el-cascader>
      </div>

      <!-- 表单认证(详细地址) -->
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="form.address"  prefix-icon="el-icon-user" placeholder="请输入详细地址"  autocomplete="off" :disabled="isDisable"></el-input>
      </el-form-item>

      <!-- 表单认证(社区编号) -->
      <el-form-item label="社区编号" prop="villageCode" v-if="showInput">
        <el-input type="text" placeholder="请输入内容" v-model="villageCode_suffix"  :disabled="!isShowCascader"  maxlength="3" show-word-limit>
          <template slot="prepend">{{villageCode_prefix}}</template>
        </el-input>
      </el-form-item>

    </el-form>

    <!-- 弹窗底部 -->
    <span slot="footer" class="dialog-footer">
      <vs-divider></vs-divider>
      <vs-button
        size="small"
        class="mr-5"
        type="relief"
        color="grey"
        @click="handleClose"
      >
        取 消
      </vs-button>
      <vs-button
        size="small"
        type="relief"
        color="danger"
        class="mr-5 vs-con-loading__container"
        @click="delSpace"
        v-if="isDisable && this.operate == 'DELETE'"
      >
        删除
      </vs-button>
      <vs-button
        size="small"
        type="relief"
        color="primary"
        :disabled="!validateForm"
        class="vs-con-loading__container"
        @click="submitForm('ruleForm')"
        v-if="!isDisable"
      >
        确 定
      </vs-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ["dialogFormVisible", "dialogFormData", "dialogFormOpt"],
  data() {
    return {
      villageCode_prefix: "",
      villageCode_suffix: "",
      showInput: false,
      reFresh: true,
      dialogTitle: "",
      isVisible: this.dialogFormVisible,
      operate: this.dialogFormOpt,
      isDisable: false,
      isShowCascader: false,
      cascaderPlaceholder: "请选择行政单位",
      cascader: {
        lazy: true,
        lazyLoad: this.cascaderLazyLoad,
        provinces: []
      },
      form: {
        parentCode: "",
        spaceCode: "",
        spaceName: "",
        address: "",
        areaAddress: "",
        extend: {}
      },
      rules: {
        spaceName: [
          { required: true, message: '请输入社区名称', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ]
      }
    };
  },
  computed: {
    // 监听表单认证状况
    validateForm() {
      let isValid = false;
      switch (this.operate) {
        case "CREATE":
          isValid =
            !this.$cFun.isNullOrEmptyForObJ({
              spaceName: this.form.spaceName,
              address: this.form.address,
              parentCode: this.form.parentCode,
              villageCode_suffix: this.villageCode_suffix
            });
          break;
        case "READ":
          break;
        case "UPDATE":
          isValid =
            !this.$cFun.isNullOrEmptyForObJ({
              spaceName: this.form.spaceName,
              address: this.form.address
            });
          break;
        case "DELETE":
          break;
      }
      return isValid;
    }
  },
  watch: {
    // 监听父组件传回来的值 - 表单值
    dialogFormData(val) {
      // console.log("dialogFormData", val);
      this.reFresh = false;
      // 省份数据
      this.cascader.provinces = val.provinces;
      this.cascaderPlaceholder = (val.dialogFormOpt == "CREATE") ? "请选择行政单位" : val.extend.areaAddress;
      // row
      this.form.spaceName = val.space_name;
      this.form.spaceCode = val.space_code;
      this.form.extend = val.extend;
      this.form.address = val.extend.address;
      this.form.areaAddress = val.extend.areaAddress;
      // 通知组件刷新了
      this.$nextTick(() => {
        this.reFresh = true;
      });
    },
    // 监听父组件传回来的值 - 开关值
    dialogFormVisible(val) {
      this.$cFun.changeNavBarShadow(val);
      this.isVisible = this.dialogFormVisible;
    },
    // 监听父组件传回来的值 - CURD操作值
    dialogFormOpt(val) {
      this.operate = this.dialogFormOpt;
      switch (val) {
        case "CREATE":
          this.dialogTitle = "创建社区";
          this.isDisable = false;
          this.isShowCascader = true;
          break;
        case "READ":
          this.dialogTitle = "查看社区";
          this.isDisable = true;
          this.isShowCascader = false;
          break;
        case "UPDATE":
          this.dialogTitle = "更新社区";
          this.isDisable = false;
          this.isShowCascader = false;
          break;
        case "DELETE":
          this.dialogTitle = "删除社区";
          this.isDisable = true;
          this.isShowCascader = false;
          break;
      }
    }
  },
  methods: {
    // 表单验证
    async submitForm(formName) {
      let el = event.currentTarget;
      this.$refs[formName].validate( async (valid) => {
        if (valid) {
          await this.submit();
        } else {
          return false;
        }
      })
    },
    // 提交事件
    async submit() {
      let el = event.currentTarget;
      // 开启loading
      this.$cFun.openDomLoad(this.$vs.loading, el);
      // 判断下操作
      if (this.operate === "CREATE") {
        await this.addSpace();
      } else if (this.operate === "UPDATE") {
        await this.updateSpace();
      }
      // 关闭loadiing
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el);
      }, 1000);
    },
    // 删除事件
    async delRow() {
      let el = event.currentTarget;
      // 开启loading
      this.$cFun.openDomLoad(this.$vs.loading, el, "danger");
      // 提交API删除角色
      await this.delRole();
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el);
      }, 1000);
    },
    // 添加社区
    async addSpace() {
      try {
        let params = {
          spaceCode: this.villageCode_prefix + this.villageCode_suffix,
          spaceName: this.form.spaceName,
          parentCode: this.form.parentCode,
          extend: Object.assign(
            this.form.extend,
            { address: this.form.address },
            { areaAddress: this.form.areaAddress }
          )
        };
        // let result = await this.$apis.ODC("添加空间", params);
        let result = await this.$apis.ODC("新建小区接口", params);
        if (this.$apis.isOk(result)) {
          this.$cFun.showNotify(this.$vs.notify, "success", this.$apis.getResMessage(result));
          this.handleClose(this, true);
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
    },
    // 更新社区
    async updateSpace() {
      try {
        let params = {
          spaceName: this.form.spaceName,
          spaceCode: this.form.spaceCode,
          extend: Object.assign(this.form.extend, {
            address: this.form.address
          })
        };
        console.log(params);
        let result = await this.$apis.ODC("修改空间", params);
        if (this.$apis.isOk(result)) {
          this.$cFun.showNotify(this.$vs.notify, "success", this.$apis.getResMessage(result));
          this.handleClose(this, true);
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
    },
    // 删除空间
    async delSpace() {
      try {
        let params = {
          spaceCode: this.form.spaceCode
        };
        let result = await this.$apis.ODC("删除空间", params);
        if (this.$apis.isOk(result)) {
          this.$cFun.showNotify(this.$vs.notify, "success", this.$apis.getResMessage(result));
          this.handleClose(this, true);
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
    },
    // 获取最新的小区code
    async getSpaceCode() {
      try {
        let params = {
          parentCode: this.form.parentCode
        };
        let result = await this.$apis.ODC("获取小区模版数据", params);
        if (this.$apis.isOk(result)) {
          let spaceCode = result.spaceCode
          this.villageCode_prefix = spaceCode.substring(0, spaceCode.length-3)
          this.villageCode_suffix = spaceCode.substring(spaceCode.length-3)
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
    },
    // 获取空间列表 (市区居委)
    async getCascaderData(partentCode) {
      try {
        let result = await this.$apis.ODC("获取空间列表", { partentCode });
        if (this.$apis.isOk(result)) {
          return result.data;
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
          return [];
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
        return [];
      }
    },
    // 三级省市区联动 懒加载
    async cascaderLazyLoad(node, resolve) {
      var nodes = "";
      const { level, value } = node;
      if (level === 0) {
        let parentList = this.cascader.provinces;
        nodes = parentList.map(item => ({
          value: item.space_code,
          label: item.space_name,
          leaf: level >= 4
        }));
      } else {
        let parentList = await this.getCascaderData(value);
        nodes = parentList.map(item => ({
          value: item.space_code,
          label: item.space_name,
          leaf: level >= 4
        }));
      }
      resolve(nodes);
    },
    // 表单校验
    isValidForm() {
      return new Promise((resolve, reject) => {
        const isValid = this.$validator.validateAll();
        isValid
          .then(valid => {
            resolve(valid);
          })
          .catch(() => {
            reject(false);
          });
      });
    },
    // 关闭弹框回调
    handleClose(done, isRefresh = false) {
      this.isVisible = false;
      if (this.isVisible == false) {
        this.emptyData();
        this.$emit("handleDiaLogClose", isRefresh);
      }
    },
    // 省市区三级联动 变化时
    async handleCascaderChange(value) {
      let arrLen = value.length;
      let lastSpaceCode = value[arrLen - 1];
      this.form.parentCode = lastSpaceCode;
      // 当前选中的label
      if (arrLen > 0) {
        let pathLabels = this.$refs["cascader"].getCheckedNodes()[0].pathLabels;
        let address = pathLabels.join(" / ");
        this.form.areaAddress = address;
        // 自动填写地址
        let copyPathLabels = address.split(" / ");
        copyPathLabels.splice(
          pathLabels.findIndex(item => item == "市辖区"),
          1
        );
        this.form.address = copyPathLabels.join("") + (this.form.spaceName ?  this.form.spaceName : "");
      } else {
        this.form.areaAddress = "";
        this.form.address = "";
      }
      await this.getSpaceCode()
      this.showInput = !this.showInput
    },
    // 清空数据
    emptyData() {
      this.showInput = false
      this.form.parentCode = "";
      this.form.spaceName = "";
      this.form.address = "";
      this.form.areaAddress = "";
    }
  },
  components: {}
};
</script>

<style lang="scss" scope="scope">
.VillageDialogCURD {
  width: 35%;
  min-width: 300px;
}
</style>
